.spinner {
    width: 50px;
    height: 50px;
    margin: 100px auto;
    border-radius: 50%;
    border: 5px solid #333;
    border-top-color: #0fdaf1;
    border-bottom-color: #0fdaf1;
    -webkit-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
  }
  
  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  
  @keyframes spin {
    100% {
      transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
    }
  }
  