@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sofia+Sans&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Sofia Sans', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*::-webkit-scrollbar {
  width: 15px;
}

*::-webkit-scrollbar-track {
  border-radius: 8px;
  background-color: transparent;
  border-radius: 0px;
}

*::-webkit-scrollbar-thumb {
  height: 10px;
  border-radius: 8px;
  border: 4px solid transparent;
  background-clip: content-box;
  background-color: rgb(83, 83, 83);
}

*::-webkit-scrollbar-thumb:hover {
  background-color: rgb(164, 164, 164);
}

#heading {
  font-family: 'Merriweather', serif;
}